import logo from './logo.svg';

// style
import './App.css';

// components
import Navbar from './components/Navbar';

// pages
import Landing from "./pages/Landing"

// router
import { Routes, Route } from "react-router-dom"

function App() {
  return (
    <div className="App">
        <Navbar/>
          <Routes>

            {/* Home */}
            <Route path="/" exact element={<Landing/>}/>
        </Routes>
    </div>
  );
}

export default App;
