import React, {useState} from 'react'
import hero_img from "../assets/hero_img.png"
import logo_img from "../assets/meroelogo.jpg"

const Landing = () => {

  const [menu, setMenu] = useState(false);

  return (
    <div className="w-full h-full bg-black">
        
        {/* Hero */}
        <div className="">
        <div className="container mx-auto md:flex items-center gap-8">
                    <div className="p-10 text-white w-full">
                        <h1 className="text-left text-4xl leading-10 font-extrabold f-f-l">Build, tinker &<br></br>unleash your inner<br></br>tech wizard!</h1>
                        <div className="text-left text-gray-400 leading-5 f-f-r text-lg lg:text-base sm:pb-0 pt-10 xl:pt-6">
                            <h2>Join a global community of makers & explore the frontiers of robotics, the power of AI/ML, and the possibilities of web3 through our diy tech projects.</h2>
                        </div>
                        <div className="pt-5 lg:flex gap-x-5">
                            <button className="hidden md:block hover:opacity-90 text-base w-full xl:text-base xl:w-6/12 mt-4 xl:mt-8 f-f-r bg-green-400 text-black font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">Start Exploring</button>
                            <button className="hidden md:block hover:opacity-90 text-base w-full xl:text-base xl:w-4/12 lg:ml-2 xl:ml-2 mt-4 xl:mt-8 f-f-r py-4  bg-gray-700 text-white font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">Learn More</button>
                        </div>
                    </div>
                    <img className="w-full mt-8 md:mt-0 object-fill md:w-1/2 md:-ml-4 lg:-ml-4 xl:ml-0" src={hero_img} alt="sample page" role="img" />
                    <button className="md:hidden hover:opacity-90 text-base w-full xl:text-base xl:w-6/12 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-700 text-white font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">Start Exploring</button>
                    <button className="md:hidden hover:opacity-90 text-base w-full xl:text-base xl:w-4/12 xl:ml-2 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-200 text-indigo-600 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">Learn More</button>
                </div>
          </div>

        {/* Description Card: */}
        <div className="bg-black p-10 ml-10 mr-10">
          <section className="bg-gray-900 container py-10 p-10 border border-green-400 rounded-lg">
            <div className="flex justify-center items-center flex-col">
              <div className="lg:text-4xl md:text-3xl text-3xl font-white font-bold leading-10 text-center text-white ">
                <h1>Join The Maker Movement</h1>
              </div>
              <div className="pt-10 grid lg:grid-cols-3 md:grid-cols-2 justify-center items-center xl:gap-y-16 gap-y-20 gap-x-16 lg:gap-x-20 xl:gap-x-0 lg:px-10 xl:px-0">
                <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
                  <div className="mb-6">
                    <svg className width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M24 13.3333H32L28 8L24 13.3333ZM24 18.6667H32L28 24L24 18.6667Z" fill="#C7D2FE" />
                      <path d="M1.33333 0H9.33333V32H1.33333C0.979711 32 0.640572 31.8595 0.390523 31.6095C0.140475 31.3594 0 31.0203 0 30.6667V1.33333C0 0.979711 0.140475 0.640572 0.390523 0.390523C0.640572 0.140475 0.979711 0 1.33333 0Z" fill="#818CF8" />
                      <path d="M12 0H20C20.3536 0 20.6928 0.140475 20.9428 0.390523C21.1929 0.640572 21.3333 0.979711 21.3333 1.33333V30.6667C21.3333 31.0203 21.1929 31.3594 20.9428 31.6095C20.6928 31.8595 20.3536 32 20 32H12V0Z" fill="#6366F1" />
                    </svg>
                  </div>
                  <div className="text-white text-2xl font-semibold text-center">
                    <h2>Project Based</h2>
                  </div>
                  <div className="text-gray-500 mt-2 text-lg text-center ">
                    <p>Explore projects and walk through step by step tutorials for your diy tech projects.</p>
                  </div>
                </div>
                <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
                  <div className="mb-6">
                    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M5.33325 1.33337H30.6666L26.6666 9.33337H1.33325L5.33325 1.33337Z" fill="#818CF8" />
                      <path d="M5.33325 12H30.6666L26.6666 20H1.33325L5.33325 12Z" fill="#6366F1" />
                      <path d="M5.33325 22.6667H30.6666L26.6666 30.6667H1.33325L5.33325 22.6667Z" fill="#C7D2FE" />
                    </svg>
                  </div>
                  <div className="text-white text-2xl font-semibold text-center">
                    <h2>Connection</h2>
                  </div>
                  <div className="text-gray-500 mt-2 text-lg text-center">
                    <p>Connect with others going through the same projects & share your journey through it.</p>
                  </div>
                </div>
                <div className="cursor-pointer hover:shadow py-6 xl:px-4 rounded xl:w-96 w-60 flex justify-center items-center flex-col">
                  <div className="mb-6">
                    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M30.6667 8.00004V2.66671C30.6667 2.31309 30.5262 1.97395 30.2762 1.7239C30.0261 1.47385 29.687 1.33337 29.3334 1.33337H2.66671C2.31309 1.33337 1.97395 1.47385 1.7239 1.7239C1.47385 1.97395 1.33337 2.31309 1.33337 2.66671V8.00004H30.6667Z" fill="#6366F1" />
                      <path d="M1.33337 10.6667V29.3334C1.33337 29.687 1.47385 30.0262 1.7239 30.2762C1.97395 30.5263 2.31309 30.6667 2.66671 30.6667H9.33337V10.6667H1.33337Z" fill="#C7D2FE" />
                      <path d="M12 30.6667H29.3333C29.687 30.6667 30.0261 30.5263 30.2761 30.2762C30.5262 30.0262 30.6667 29.687 30.6667 29.3334V10.6667H12V30.6667Z" fill="#818CF8" />
                    </svg>
                  </div>
                  <div className="text-white text-2xl font-semibold text-center">
                    <h2>Collect</h2>
                  </div>
                  <div className="text-gray-500 mt-2 text-lg text-center">
                    <p>Earn milestone rewards and connect your infinity stones of DIY tech projects that you can showcase.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* Footer: */}
        <div className="pt-16">
            <div className="w-full border-gray-600 border-t lg:w-11/12 md:w-11/12 lg:mx-auto md:mx-auto text-white">
                <div className="container mx-auto py-12">
                    <div className="xl:flex lg:flex md:flex pt-6">
                        <div className="w-11/12 xl:w-3/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0">
                            <div className="flex items-center mb-6 xl:mb-0 lg:mb-0">
                                <img src={logo_img} className="w-12 h-12 border border-gray-600" alt='meroe labs logo'></img>
                                <p className="ml-3 font-bold text-xl">Meroe Labs</p>
                            </div>
                        </div>
                        <div className="text-left w-11/12 xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                            <ul>
                                <li className="text-white font-semibold text-lg mb-6">Meroe Labs</li>
                                <li className="text-base text-gray-500 hover:text-gray-700 mb-5">
                                    <a href="">Explore</a>
                                </li>
                                <li className="text-base text-gray-500 hover:text-gray-700 mb-5">
                                    <a href="">All projects</a>
                                </li>
                                <li className="text-base text-gray-500 hover:text-gray-700 mb-5">
                                    <a href="">About</a>
                                </li>
                            </ul>
                        </div>
                        <div className="text-left w-11/12 xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                          <ul>
                                  <li className="text-white font-semibold text-lg mb-6">Builders</li>
                                  <li className="text-base text-gray-500 hover:text-gray-700 mb-5">
                                      <a href=")">Create</a>
                                  </li>
                                  <li className="text-base text-gray-500 hover:text-gray-700 mb-5">
                                      <a href="">Share</a>
                                  </li>
                                  <li className="text-base text-gray-500 hover:text-gray-700 mb-5">
                                      <a href="">Learn</a>
                                  </li>
                              </ul>
                        </div>
                        <div className="text-left w-11/12 xl:w-1/6 lg:w-2/5 mx-auto lg:mx-0 xl:mx-0 pt-3 xl:flex xl:justify-end pl-3 sm:pl-0">
                            <ul>
                                <li className="text-white font-semibold text-lg mb-6">Community</li>
                                <li className="text-base text-gray-500 hover:text-gray-700 mb-5">
                                    <a href=")">About Us</a>
                                </li>
                                <li className="text-base text-gray-500 hover:text-gray-700 mb-5">
                                    <a href="">Connect</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Landing
